import * as React from 'react';
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'


import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import { DepositoJudicialContext } from '../../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
import { statusFinalSubstituicao } from '../../../../../utils/data'
import { format } from 'date-fns'
import { AuthContext } from '../../../../../contexts/LegalInsights/auth';




const GridSubstituicao = ( { dataFilter } ) => {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const { substituicao, getSubstituicao, atualizaSubstituicaoPorIdProcesso } = React.useContext(DepositoJudicialContext)
    const { user } = React.useContext(AuthContext)
    const [_statusFinal] = React.useState(statusFinalSubstituicao())
    const [ editarGrid, setEditarGrid ] = React.useState(true)
    
    React.useEffect(()=>{
        getSubstituicao()
        if(user.tipoUsuario !== "Administrador Global"){
            setEditarGrid(false)
        }
    },[])

    React.useEffect(()=>{
        if(substituicao.length > 0){
            if(user.tipoUsuario !== "Administrador Global"){
                const _substituicao = substituicao.filter( (item)=> item.empresa === user.nomeCliente)
                setRows(_substituicao)
            }

            if(user.tipoUsuario === "Administrador Global"){
                setRows(substituicao)
            }
        }    
    },[substituicao])

    const handleRowEditStop = (params, event) => () => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })        
    }

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false }
        setRows(prevRows => prevRows.map(row => (row.id === updatedRow.id ? updatedRow : row)))   
        await atualizaSubstituicaoPorIdProcesso(newRow.id, newRow)    
        await getSubstituicao()
        return updatedRow

    };

    const handleRowModesModelChange = (newRowModesModel) => () => {
        setRowModesModel(newRowModesModel);
    };

    const handleValueGetterDate = (params) => {
        const value = params.row[params.field]
    
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {    
            const [day, month, year] = value.split("/").map(Number)
            
            const date = new Date(year, month - 1, day)            
            
            return isNaN(date.getTime()) ? null : date
        }

        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
            const date = new Date(value);
            return isNaN(date.getTime()) ? null : date;
        }
        
        return null
    }

    const handleValueFormatterDate = (params) => {
        if (!params.value) return ''
        return format(new Date(params.value), 'dd/MM/yyyy')
    }


    const columns = [
    { field: "id", headerName: "ID", hide: true, headerAlign: 'center' },

    {
        field: 'actions',
        type: 'actions',
        headerName: 'Editar registro',
        width: 150,
        cellClassName: 'actions',
        getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
            return [
            <GridActionsCellItem
                key={id}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                    color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
                key={id}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
            />,
            ];
        }

        return [
            <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            />,
        ]
        },
    },
    { field: 'empresa', headerName: "Empresa", headerAlign: 'center', align: 'center',  width: 250 },    
    { field: "cnpj", headerName: "CNPJ",  headerAlign: 'center', align: 'center',  width: '' },
    { field: "autor", headerName: "Autor", headerAlign: 'center', align: 'center',  width: 330 },
    { field: "natureza", headerName: "Natureza", headerAlign: 'center', align: 'center',  width: 150 },
    { field: "processo", headerName: "Processo", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "tribunal", headerName: "Tribunal", headerAlign: 'center', align: 'center',  width: 200  },
    { field: "vara", headerName: "Vara", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "comarca", headerName: "Comarca", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "status", headerName: "Status", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "dataDoArquivamento", headerName: "Data do arquivamento", headerAlign: 'center', align: 'center', width: 200, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,            
    },
   
    { field: "momentoProcessual", headerName: "Momento processual", headerAlign: 'center', align: 'center',  width: 300 },
    { field: "instancia", headerName: "Instância", headerAlign: 'center', align: 'center',  width: 300 },
    { field: "tipoConta", headerName: "Tipo de conta", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "oQueFazer", headerName: "O que fazer", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "dataDoDepostoAberturaContaJUD", headerName: "Data do depósito/abertura conta jud", headerAlign: 'center', align: 'center',  width: 200, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate
    },
    { field: "dataDoSaldo", headerName: "Data do Saldo", headerAlign: 'center', align: 'center',  width: 200, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
    },
    { field: "valorRecuperacao", headerName: "Valor recuperação", headerAlign: 'center', align: 'center',  width: 200  },
    { field: "valorParaGestao", headerName: "Valor para gestão", headerAlign: 'center', align: 'center',  width: 200  },
    { field: "banco", headerName: "Banco", headerAlign: 'center', align: 'center',  width: 250 },
    { field: "idConta", headerName: "Id conta", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "coBase", headerName: "Co base", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "coEstab", headerName: "Co estab", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "coEmpregado", headerName: "Co empregado", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "dtaOrigemDocumento", headerName: "Data origem documento", headerAlign: 'center', align: 'center', editable: editarGrid, width: 200, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate
    },
    { field: "agencia", headerName: "Agência", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "operacao", headerName: "Operação", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "conta", headerName: "Conta", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "dv", headerName: "Dv", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "dataDaPeticao", headerName: "Data da petição", headerAlign: 'center', align: 'center', width: 200, editable: editarGrid, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate
    },
    { field: "numeroDaApolice", headerName: "Número da apolice", headerAlign: 'center', align: 'center',  width: 200, editable: editarGrid },
    { field: "vencimentoApolice", headerName: "Vencimento da apolice", headerAlign: 'center', align: 'center',  width: 200, editable: editarGrid },
    { field: "seguradora", headerName: "Seguradora", headerAlign: 'center', align: 'center',  width: 200, editable: editarGrid },
    { field: "valorCreditado", headerName: "Valor creditado", headerAlign: 'center', align: 'center',  width: 200, editable: editarGrid },
    { field: "dataDoCredito", headerName: "Data do credito", headerAlign: 'center', align: 'center',  width: 200, editable: editarGrid, type: 'date' ,
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate
    },
    { field: "contaCreditada", headerName: "Conta creditada", headerAlign: 'center', align: 'center',  width: 200, editable: editarGrid, },
    { field: "andamentoProcessoAposProtocolo", headerName: "Adamento processo apos protocolo", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "fee", headerName: "Fee", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "proximaFase", headerName: "Próxima fase", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "valorConciliado", headerName: "Valor conciliado", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "controleEmpresa", headerName: "Controle empresa", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "apontamentoObservacoes", headerName: "Apontamento observações", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "statusFinal", headerName: "Status final", headerAlign: 'center', align: 'center', editable: editarGrid, width: 400, type: 'singleSelect',
        valueOptions: ()=>{
            return _statusFinal.sort((a, b) => a.localeCompare(b))
        }
    },
    ]

  return (
    <Box
      sx={{
        height: '80vh',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={dataFilter.length > 0 ? dataFilter : rows }
        columns={columns}
        columnVisibilityModel={{
            id: false,
            actions: editarGrid
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
}

GridSubstituicao.propTypes ={
    dataFilter: PropTypes.array
}

export default GridSubstituicao