import React, { useContext, useState } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import './index.css'
import { Box  } from '@mui/material'

import ReactLoading from 'react-loading'
import GridLevantamento from './GridLevantamento'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { ButtonLabelComponent, ButtonLabelSearchComponent } from '../../../../Components/Commons/Button'
import useForm from '../../../../hooks/useForm'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import { DepositoJudicialContext } from '../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'





const DepositoJudicialLevantamento = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload } = useContext(LegalInsightsCrudContext)
    const { user, load } = useContext(AuthContext)
    const [form, handleInputChange, clear] = useForm({ processo: '' })
    const { levantamento, getLevantamentos } = React.useContext(DepositoJudicialContext)
    const [ rowFilter, setRowFilter] = useState([])

    const handleLimparFiltro = async () => {
        showToast('success','Registro atualizado com sucesso!')
        getLevantamentos()
        setRowFilter([])
        clear()        
    }

    const handleSearchProcess = () => {     
        const _levantamento = levantamento.filter( item => item.processo.includes(form.processo))
        
        if(_levantamento.length === 0 ){
            return showToast('warning','Nenhum registro encontrado!')
        } 

        setRowFilter(_levantamento)
    }


    return(
        <div className="modulo-deposito-judicial-container">
            <Header />   

            <div className="deposito-judicial-container">
                <div className="deposito-judicial-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('depositoJudicial', user?.perfil)}/>
                </div>

                <div className="deposito-judicial-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header">       
                                <ButtonLabelSearchComponent label={'Pesquisar por processo'} value={form.processo} name={'processo'} onchange={handleInputChange} onClick={handleSearchProcess}/> 
                                <div>                          
                                    <ButtonLabelComponent label={`Limpar filtro`} onClick={handleLimparFiltro} info />                                
                                </div>                         
                            </div>                            
                            
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                       <GridLevantamento dataFilter={rowFilter} />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                    </div>
                </div>

                <div className="deposito-judicial-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}


export default DepositoJudicialLevantamento
